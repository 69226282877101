import React, { FC, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';

import { getCountryDisplayName, getCurrentCountry } from '../../../utils/countries';

export type LogoProps = {
    linkTo: string;
};

const country = getCurrentCountry();
const LogoCountrySpecific = lazy(() =>
    import(`../../../images/Logo.${country === 'default' ? 'ukraine' : country}`).catch(() => {
        // in case we couldn't find file, we're skipping action
    })
);
const Logo: FC<LogoProps> = ({ linkTo }) => {
    return (
        <div className={'logo-container'}>
            <Suspense fallback={<h4>{getCountryDisplayName(country)}</h4>}>
                <Link to={linkTo}>
                    <LogoCountrySpecific />
                </Link>
            </Suspense>
        </div>
    );
};

export default Logo;
