import * as React from 'react';
import { useEffect } from 'react';
import { isBoolean, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';
import styled from 'styled-components';

import { useStepperContext } from '../StepperContext';
import { CheckBoxContainer } from '../utils/utils';

interface Props {
    setSelectedCategory: (category: string) => void;
    selectedCategory: string;
    resetUaopFlag: () => void;
}

const CategoryAssistanceSelectionStep: React.FC<Props> = ({ setSelectedCategory, selectedCategory, resetUaopFlag }) => {
    const { disableNextStep, enableNextStep } = useStepperContext();
    const { categories, categoriesToCategoriesFullNames, categoryConfig, metaCategoryConfig } = useSelector(
        (state: State) => state.appConfig.entitlementsConfig
    );

    useEffect(() => resetUaopFlag(), []);

    useEffect(() => {
        selectedCategory ? enableNextStep() : disableNextStep();
    }, [selectedCategory]);

    const usedMetaCategoriesFromCategoryConfig = uniq(
        categoryConfig.filter((c) => !c.readonly).map((c) => c.metaCategory)
    );
    const usedMetaCategories = metaCategoryConfig
        .filter((mc) => usedMetaCategoriesFromCategoryConfig.includes(mc.abbreviation))
        .sort((a, b) => a.order - b.order);

    const categoriesToShow = categories
        .filter((category) => {
            const cc = categoryConfig.find((cc) => cc.category === category);
            if (!cc) return false;
            if (!isBoolean(cc.readonly)) return false;

            return !cc.readonly;
        })
        .sort((a, b) => {
            const ccA = categoryConfig.find((cc) => cc.category === a);
            const ccB = categoryConfig.find((cc) => cc.category === b);

            // Check if both categories have 'orderInMetaCategory'
            const orderA = ccA?.orderInMetaCategory;
            const orderB = ccB?.orderInMetaCategory;

            // If both categories have 'orderInMetaCategory', sort by that
            if (typeof orderA === 'number' && typeof orderB === 'number') {
                return orderA - orderB;
            }

            // If only one category has 'orderInMetaCategory', prioritize that category
            if (typeof orderA === 'number') return -1;
            if (typeof orderB === 'number') return 1;

            // If neither has 'orderInMetaCategory', leave them in their default order
            return 0;
        });

    return (
        <>
            <h6
                style={{
                    marginTop: '32px',
                }}
            >
                Assistance Category
            </h6>
            <div className="container">
                <div className="row">
                    {usedMetaCategories.map((metaCategory) => (
                        <div className="col" key={metaCategory.abbreviation}>
                            <CheckBoxContainer marginTop="0px">
                                <p>
                                    <b>{metaCategory.name}</b>
                                </p>
                                {categoriesToShow
                                    .filter((category) => {
                                        const config = categoryConfig.find((cc) => cc.category === category);
                                        return config.metaCategory === metaCategory.abbreviation;
                                    })
                                    .map((category) => (
                                        <React.Fragment key={category}>
                                            <StyledLabel>
                                                <input
                                                    checked={selectedCategory === category}
                                                    onChange={() => setSelectedCategory(category)}
                                                    type="radio"
                                                />{' '}
                                                {categoriesToCategoriesFullNames[category]} ({category})
                                            </StyledLabel>
                                        </React.Fragment>
                                    ))}
                            </CheckBoxContainer>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

const StyledLabel = styled.label`
    font-weight: normal;
`;

CategoryAssistanceSelectionStep.displayName = 'CategoryAssistanceSelectionStep';

export { CategoryAssistanceSelectionStep };
