import { ManagerPermission } from '../../permission-profiles/permission';

import AsyncTasksPage from './AsyncTasksPage';
import { bindActionCreators } from 'redux';
import State from '@wfp-root-app/store/state';
import { connect } from 'react-redux';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes } from '../../utils/asyncTasks';
import { ActionCreators as BeneficiaryActionCreators } from '../beneficiaries';
import { hasFeatureAccess } from '../../login/auth';
import AsyncTasksListView from '../AsyncTasksListView';

function mapDispatchToProps(dispatch: any) {
    return {
        authorizeTask: bindActionCreators(AsyncActionCreators.authorizeAsyncTask, dispatch),
        downloadFile: bindActionCreators(AsyncActionCreators.downloadFile, dispatch),
        loadAsyncTasksList: (page, limit) =>
            dispatch(AsyncActionCreators.loadAsyncTasksList(page, limit, AsyncTasksTypes.UPLOAD_REMOVE_DEDUPLICATIONS)),
        uploadFile: (
            data: File,
            dryRun?: boolean,
            mapping?: Record<number, string>,
            dryRunAsyncTaskId?: string,
            additionalData: Record<string, any> = {}
        ) => {
            const params: any = {
                data,
                mapping,
                startIndex: 2,
                schedule: !dryRun,
                ...additionalData,
            };

            if (dryRun) {
                params.dryRun = true;
            }
            if (dryRunAsyncTaskId) {
                params.dryRunAsyncTaskId = dryRunAsyncTaskId;
            }
            return dispatch(BeneficiaryActionCreators.importRemoveDeduplications(params));
        },
        clearUploadTask: bindActionCreators(BeneficiaryActionCreators.clearUploadTask, dispatch),
    };
}

function mapStateToProps(state: State) {
    const canImport = hasFeatureAccess(state.auth, ManagerPermission.beneficiariesEntitlementPark);
    const canAuthorizeTask = hasFeatureAccess(state.auth, ManagerPermission.beneficiariesEntitlementVerify);
    const canDownload = hasFeatureAccess(state.auth, ManagerPermission.beneficiariesDownload);

    return {
        canPark: canImport && state?.auth?.manager?.agency !== 'ALL',
        canPost: canAuthorizeTask,
        canDownload: canDownload,
        tasks: state.asyncTasks.asyncTasks[AsyncTasksTypes.UPLOAD_REMOVE_DEDUPLICATIONS],
        authorizedManagerId: state.auth.manager ? state.auth.manager.id : null,
        exampleUploadFile: 'Remove UAOP Template.xlsx',
        name: 'Upload Remove Deduplication File',
        type: AsyncTasksTypes.UPLOAD_REMOVE_DEDUPLICATIONS,
        uploadDescription: 'Upload the Remove Deduplication XLSX.GPG file by clicking the button below.',
        exampleLinkTitle: 'See example import file',
        listView: AsyncTasksListView,
        uploadTaskState: state.uploadTaskState,
        shouldRunDryRun: true,
        mapping: {
            taxId: 1,
            organization: 2,
            category: 3,
            currency: 4,
            reloadAmount: 5,
            reloadDate: 6,
            expiry: 7,
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AsyncTasksPage);
