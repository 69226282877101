import classNames from 'classnames';
import * as React from 'react';
import { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CloudUploadIcon } from '../../../../icons/CloudUploadIcon.svg';
import { ActionCreators as AsyncActionCreators } from '../../../../utils/asyncTasks';
import { UploadStatuses, UploadStatusWidget } from '../../../../utils/import';
import { ActionCreators as BeneficiaryActionCreators, UploadTaskState } from '../../../beneficiaries';
import { UaopFlags } from '../../DryRunDialog';
import { HouseholdExclusionModelImpl, HouseholdExclusionType, mapStrategyFlag } from '../utils/utils';
import State from '@wfp-root-app/store/state';

interface FileUploadStepProps {
    selectedAssistanceCategory: string;
    selectedDeduplicationCategories: string[];
    selectedFlag: UaopFlags;
    hExclusion: HouseholdExclusionType;
    setUploadTaskStatus: (taskStatus: UploadTaskState) => void;
    taskState: UploadTaskState;
    setSelectedFile: (file: File) => void;
    selectedFile: File;
    setRefreshHandle: (flag: boolean) => void;
    showHouseholdExclusionConfirmation: boolean;
    showDeduplicationCategoriesConfirmation: boolean;
}

const FileUploadStep: FC<FileUploadStepProps> = (props) => {
    const [dragActive, setDragActive] = useState(false);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [showUploadStatusWidget, setShowUploadStatusWidget] = useState(false);
    const beneficiariesImportMapping = useSelector((state: State) => state.appConfig.beneficiariesImportMapping);

    const getUploadModel = (dryRun: boolean, file: File) => ({
        data: file,
        uaopFlag: props.selectedFlag,
        assistanceCategory: props.selectedAssistanceCategory,
        deduplicationCategories: [props.selectedAssistanceCategory, ...props.selectedDeduplicationCategories],
        overlapPeriod: HouseholdExclusionModelImpl[props.hExclusion].value,
        schedule: !dryRun,
        dryRun,
        mapping: beneficiariesImportMapping,
        startIndex: 2,
    });

    const handleFileSelection = (files: FileList) => {
        if (files.length) {
            props.setSelectedFile(files[0]);

            const model = getUploadModel(true, files[0]);
            props.setUploadTaskStatus(new UploadTaskState(UploadStatuses.pending));
            dispatch(BeneficiaryActionCreators.importBeneficiaries(model));
            props.setRefreshHandle(true);
            setShowUploadStatusWidget(true);
        }
    };

    return (
        <div className={'wfp-upload-step'}>
            {!props.selectedFile && (
                <div
                    className={classNames('wfp-upload-step__drag-drop-area text-secondary bg-light', {
                        'drag-active': dragActive,
                    })}
                    onClick={() => {
                        fileInputRef.current.click();
                    }}
                    onDragEnter={(event) => {
                        if (!dragActive) {
                            setDragActive(true);
                        }
                    }}
                    onDragLeave={(event) => {
                        if (dragActive) {
                            setDragActive(false);
                        }
                    }}
                    onDragOver={(event) => {
                        event.preventDefault();
                    }}
                    onDragStart={(event) => {
                        if (!dragActive) {
                            setDragActive(true);
                        }
                        event.preventDefault();
                    }}
                    onDrop={(event) => {
                        event.preventDefault();
                        setDragActive(false);
                        const dt = event.dataTransfer;

                        handleFileSelection(dt.files);
                    }}
                >
                    <span className={'drag-drop-icon'}>
                        <CloudUploadIcon />
                    </span>
                    <h4 className={'text-center'} style={{ width: '360px' }}>
                        Drag and Drop File or Click to Choose
                    </h4>
                    <small className={'mt-2'}>Your file is going to be validated right after its attach</small>
                    <input
                        hidden={true}
                        onChange={(event) => {
                            const files = event.target.files;

                            handleFileSelection(files);
                        }}
                        ref={fileInputRef}
                        type="file"
                    />
                </div>
            )}

            <div className={'wfp-upload-step__summary mt-3'}>
                <div>
                    <span className={'font-weight-bold'}>Assistance Category: </span>
                    <span>{props.selectedAssistanceCategory}</span>
                </div>
                {props.showDeduplicationCategoriesConfirmation && (
                    <div>
                        <span className={'font-weight-bold'}>Inter-Category: </span>
                        <span>
                            {props.selectedDeduplicationCategories.length > 0
                                ? props.selectedDeduplicationCategories.join(', ')
                                : 'none'}
                        </span>
                    </div>
                )}
                <div>
                    <span className={'font-weight-bold'}>Strategy: </span>
                    <span>{mapStrategyFlag(props.selectedFlag)}</span>
                </div>
                {props.showHouseholdExclusionConfirmation && (
                    <div>
                        <span className={'font-weight-bold'}>Previous Household Exclusion: </span>
                        <span>{HouseholdExclusionModelImpl[props.hExclusion].visibleName}</span>
                    </div>
                )}
                <div>
                    <span className={'font-weight-bold'}>Enclosed file: </span>
                    <span>{props.selectedFile?.name || 'no file'}</span>
                </div>

                {props.selectedFile && showUploadStatusWidget && (
                    <UploadStatusWidget
                        downloadFile={(type, id) => {
                            dispatch(AsyncActionCreators.downloadFile(type, id));
                        }}
                        importError={props.taskState.error}
                        importStatus={props.taskState.status}
                        importTask={props.taskState.task}
                    />
                )}
            </div>
        </div>
    );
};

FileUploadStep.displayName = 'FileUploadStep';

export { FileUploadStep };
