import { MetaCategories } from './uaopFlags';
import { UaopFlags } from '../../DryRunDialog';

export const getRecommendedUaopFlagPerMetaCategory = (metaCategory: MetaCategories) => {
    switch (metaCategory) {
        case MetaCategories.NFI:
            return UaopFlags.IncrementalAnyAmount;
        default:
            return UaopFlags.IncrementalDeltaAmountYesOption;
    }
};
