import React from 'react';

export interface RowMenuProps<T extends string> {
    tabs: T[];
    activeTab: T;
    onSelectTab: (tab: T) => void;
    wrapElements?: boolean;
    tabDisplayNames?: Partial<Record<T, string>>;
}

export function RowMenu<T extends string>(props: RowMenuProps<T>) {
    const { tabs, activeTab, onSelectTab, tabDisplayNames, wrapElements = false } = props;
    if (!tabs) return null;
    return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: wrapElements ? 'wrap' : 'nowrap' }}>
            {tabs.map((tab, index) => (
                <button
                    className={'wfp-btn ' + (activeTab === tab ? 'active' : '')}
                    key={index}
                    onClick={() => onSelectTab(tab)}
                    style={{
                        flex: 1,
                        marginBottom: 20,
                        marginLeft: 10,
                        marginRight: 10,
                    }}
                    type={'button'}
                >
                    {(tabDisplayNames && tabDisplayNames[tab]) ? tabDisplayNames[tab] : tab}
                </button>
            ))}
        </div>
    );
}
