import React from 'react';
import { RowMenu } from '../../utils/RowMenu';
import BeneficiaryImportPage from './BeneficiaryMassImport';
import TaxIdFamilyTaxIdMapping from './TaxIdFamilyTaxIdMapping';
import RemoveEntitlements from './RemoveEntitlements';
import { ActionCreators, FileUploadListType, identifierTypeToShow } from './fileUpload';
import { useDispatch, useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';
import RemoveDeduplications from './RemoveDeduplications';

const FileUploadContainer: React.FC = () => {
    const activeTab = useSelector((state: State) => state.fileUpload.activeTab);
    const beneficiaryPrimaryIdentifierType = useSelector(
        (state: State) => state.appConfig.beneficiaryPrimaryIdentifierType
    );
    const dispatch = useDispatch();

    const tabs = [
        FileUploadListType.FileUpload,
        FileUploadListType.FamilyTaxIdMapping,
        FileUploadListType.RemoveEntitlements,
        // FileUploadListType.RemoveDeduplications, TODO - first discuss logic of removal only deduplications with Houman
    ];

    const tabDisplayNames = {
        [FileUploadListType.FamilyTaxIdMapping]: `HF to HM ${identifierTypeToShow[beneficiaryPrimaryIdentifierType]} Mapping`,
    };

    const onSelectTab = (tab: FileUploadListType) => {
        dispatch(ActionCreators.changeTab(tab));
    };

    const renderFileUploadList = () => {
        switch (activeTab) {
            case FileUploadListType.FileUpload:
                return <BeneficiaryImportPage />;
            case FileUploadListType.FamilyTaxIdMapping:
                return <TaxIdFamilyTaxIdMapping />;
            case FileUploadListType.RemoveEntitlements:
                return <RemoveEntitlements />;
            case FileUploadListType.RemoveDeduplications:
                return <RemoveDeduplications />;
        }
    };

    return (
        <>
            <RowMenu activeTab={activeTab} onSelectTab={onSelectTab} tabs={tabs} tabDisplayNames={tabDisplayNames} />
            {renderFileUploadList()}
        </>
    );
};

FileUploadContainer.displayName = 'FileUploadContainer';

export { FileUploadContainer };
